import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import VerifyOTP from "../VerifyOTP";
import Style from "./LoginForm.module.scss";
import useLoginForm from "./useLoginForm";
import { useLocation } from "react-router-dom";
import RegisterModal from "../RegisterModal";

const LoginForm = () => {
  const {
    t,
    formik,
    showModal,
    mobileRef,
    isTimerRunning,
    isSubmitLoading,
    showModalRegister,
    handleClose,
    setIsTimerRunning,
    handleShowRegister,
    handleCloseRegister,
  } = useLoginForm();
  const lang = localStorage.getItem("language_type");
  const location = useLocation();
  const currentPath = location.pathname;

  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <div className={`${Style.login} ${rtl}`}>
      <p>{t("VERIFY_YOUR")}</p>

      <form onSubmit={formik.handleSubmit}>
        <Form.Group className="form-group text-start">
          <Form.Label></Form.Label>
          <Form.Control
            type="text"
            id="mobile"
            onChange={formik.handleChange}
            maxLength={10}
            value={formik.values.mobile}
            name="mobile"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="formikError">{formik.errors.mobile}</div>
          ) : null}
        </Form.Group>

        <button
          className={`${isSubmitLoading ? "loading custom_verify_btn" : " custom_verify_btn"}`}
          type="sumbit"
        >
          {t("VERIFY_BUTTON")}
        </button>
      </form>
      <VerifyOTP
        shows={showModal}
        mobileRef={mobileRef}
        isTimerRunning={isTimerRunning}
        onHides={handleClose}
        setIsTimerRunning={setIsTimerRunning}
      />

      <button className={Style.join} onClick={handleShowRegister}>
        {t("NOT_an_IKEA")}
      </button>
      <RegisterModal show={showModalRegister} handleClose={handleCloseRegister} />
    </div>
  );
};

export default LoginForm;
