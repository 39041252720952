import React from "react";
import Style from "./SubscriptionText.module.scss";

const SubscriptionSuccess = ({ t }) => {
  return (
    <div className={Style.subscription}>
      <h2 className={Style.title}>{t("DELEVERY_SUBS")}</h2>
      <div className="d-flex align-items-start">
        <img src="/assets/success.svg" alt="success" />
        <h2 className={`h2 ${Style.subtitle}`}>{t("CURRENTLY_SUBSCRIBED")}</h2>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
