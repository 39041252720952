import "../styles/common.scss";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import CommonLayout from "./Layouts/CommonLayout";
import SubscriptionPage from "../pages/Subscription";
import SummaryPage from "../pages/Summary";
import RegisterPage from "../pages/Register";

const App = () => {
  return (
    <Suspense fallback={null}>
      <CommonLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:lang" element={<HomePage />} />
          <Route path="/register/:lang" element={<RegisterPage />} />
          <Route path="/subscription/:lang" element={<SubscriptionPage />} />
          <Route path="/summary/:orderId/:lang" element={<SummaryPage />} />
        </Routes>
      </CommonLayout>
    </Suspense>
  );
};

export default App;
