import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Register } from "./api";

const useRegisterForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const { t } = useTranslation(["common"]);

  var numbers = /^\d+\.?\d*$/;

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      phone: "",
      dob: "",
      nationality: "",
      living_situation: "",
      address: "",
      sms_preferred: false,
      email_preferred: false,
      toggle: false,
    },

    validationSchema: Yup.object({
      first_name: Yup.string().required(t("First_name")),
      last_name: Yup.string().required(t("Last_name")),
      gender: Yup.string().required(t("Gender")),
      email: Yup.string().required(t("Email")),
      dob: Yup.string().required(t("DOB")),
      nationality: Yup.string().required(t("Nationality")),
      living_situation: Yup.string().required(t("Living_Situation")),
      address: Yup.string().required(t("Address")),
      // sms: Yup.bool().oneOf([true], t("Preferred_Communication")),

      phone: Yup.string()
        .matches(numbers, t("Invalid_mobile"))
        .required(t("Phone_number"))
        .min(9, t("min"))
        .max(10, t("max")),
      toggle: Yup.bool().oneOf([true], t("Toogle")),
    }),
    onSubmit: (values) => {

      //   let data = {
      //     mobile: values?.mobile,
      //     language: localStorage.getItem("language_type"),
      //   };

      setIsSubmitLoading(true);
      //   Register(data)
      //     .then((response) => {
      //       if (response?.data?.code == "200") {
      //         setIsSubmitLoading(false);

      //         handleShow();
      //       } else {
      //         setIsSubmitLoading(false);
      //         formik.setFieldError("mobile", response?.message);
      //       }
      //     })
      //     .catch(() => {
      //       setIsSubmitLoading(false);
      //     });
    },
  });
  return { t, formik };
};

export default useRegisterForm;
