import React from "react";
import Style from "./PaymentSuccess.module.scss";
import SubscriptionDate from "../SubscriptionDate";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = ({ t, userData }) => {
  const navigate = useNavigate();

  return (
    <div className={Style.root}>
      <h2 className={Style.title}>{t("PURCHASED")}</h2>
      <div className="d-flex align-items-start justify-content-center gap-1">
        <img src="/assets/success.svg" alt="success" />
        <h6 className={`h6 ${Style.subtitle}`}>{t("CURRENTLY_SUBSCRIBED")}</h6>
      </div>
      {/* <div className={Style.img_wrap}>
        <figure>
          <img src="/assets/images/subscription-poster.png" alt="poster" />
        </figure>
        <div className={Style.mobile}></div>
      </div> */}

      <div className="d-flex justify-content-center ">
        <SubscriptionDate t={t} props={userData} />
      </div>
      <button
        className={`custom_btn custom_btn-primary mt-lg-4 ${Style.btn}`}
        onClick={() => {
          navigate(`/`);
          window.location.reload();
        }}
      >
        {t("Home")}
      </button>
    </div>
  );
};

export default PaymentSuccess;
