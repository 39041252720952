import RegisterForm from "../RegisterForm";
import Style from "./Register.module.scss";
import useRegister from "./useRegister";
import { useLocation } from "react-router-dom";

const Register = () => {
  const { t } = useRegister();
  const location = useLocation();
  const currentPath = location.pathname;

  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <section className={`${Style.register} ${rtl}`}>
      <div className="section_wrap">
        <div className="inner">
          <div className={Style.outer_wrap}>
            <div className={Style.reg_head}>
              <h2 className={Style.title}>{t("WELCOME_IKEA")}</h2>
              <h3 className={`h2 ${Style.subtitle}`}>{t("Reward_EXp")}</h3>
              <p>{t("FAMILY_CONTENT")}</p>

              <figure>
                <img src="/assets/images/subscription-poster.png" alt="poster" />
              </figure>
            </div>
            <RegisterForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
