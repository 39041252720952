import React from "react";
import Style from "./SubscriptionText.module.scss";

const SubscriptionExpired = ({ t }) => {
  return (
    <div className={Style.subscription}>
      <h2 className={Style.title}>{t("EXPIRES_SOON")}</h2>

      <h2 className={`h2 ${Style.subtitle}`}>{t("EXTEND_SUBS")}</h2>
    </div>
  );
};

export default SubscriptionExpired;
