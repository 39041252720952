import Style from "./Subscription.module.scss";
import SubscriptionText from "../SubscriptionText";
import SubscriptionDate from "../SubscriptionDate";
import FormPayment from "../FormPayment";
import useSubscription from "./useSubscription";
import SubscriptionExpired from "../SubscriptionExpired";
import SubscriptionSuccess from "../SubscriptionSuccess";
import { useLocation } from "react-router-dom";

const Subscription = () => {
  const { t, userData, basicData } = useSubscription();
  let paymentOptionStatus = userData?.data?.payment_option;
  const location = useLocation();
  const currentPath = location.pathname;

  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <>
      <section className={`${Style.delivery_subscription} ${rtl}`}>
        <div className="section_wrap">
          <div className="inner">
            <div className={`row`}>
              <div className={`col-lg-6`}>
                {paymentOptionStatus == "1" && <SubscriptionText />}
                {paymentOptionStatus == "2" && <SubscriptionExpired t={t} />}
                {paymentOptionStatus == "0" && <SubscriptionSuccess t={t} />}
                <SubscriptionDate t={t} props={userData} />
                <div className="d-block d-lg-none">
                  <figure className={Style.figure}>
                    <img src="/assets/images/subscription-poster.png" alt="poster" />
                  </figure>
                </div>
              
                <FormPayment userData={userData} basicData={basicData} />
              </div>
              <div className="col-lg-6">
                <div className="d-none d-lg-block">
                  <figure className={Style.figure}>
                    <img src="/assets/images/subscription-poster.png" alt="poster" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscription;
