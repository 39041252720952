import React from "react";
import Style from "./SubscriptionText.module.scss";
import useSubscriptionText from "./useSubscriptionText";
import { useLocation } from "react-router-dom";

const SubscriptionText = () => {
  const { t, basicData } = useSubscriptionText();
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <div className={`${Style.subscription} ${rtl}`}>
      <h2 className={Style.title}>{t("DELEVERY_SUBS")}</h2>
      <h2 className={`h2 ${Style.subtitle}`}>{t("YEARLY_SUBS")}</h2>
      <p>{t("UNLOCK_BENEFITS")}</p>
      <ul>
        <li>
          {t("ONEYEAR_OF")} <span>{t("FREE_DELIVERY")}</span> {t("ON_ALL_ORDERS")}.
        </li>
        <li>
          {t("EXCLUSIVE_ACCESS")} <span>{t("FAMILY_MEM")}</span>
        </li>
      </ul>

      <h2 className={`h2 ${Style.sm_title}`}>
        {t("DHS")}
        {parseInt(basicData?.data?.amount)}/{t("YEAR")}
      </h2>
    </div>
  );
};

export default SubscriptionText;
