import { parseCookies } from "nookies";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Subscription from "../../components/Subscription";

const SubscriptionPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const cookies = parseCookies();
      if (!cookies.USER_ACCESS_TOKEN) {
        navigate("/");
      }
    };

    checkToken();
  }, [navigate]);

  return <Subscription />;
};

export default SubscriptionPage;
