import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Style from "./RegisterForm.module.scss";
import useRegisterForm from "./useRegisterForm";
import { useLocation } from "react-router-dom";

const RegisterForm = ({ handleShow }) => {
  const { t, formik } = useRegisterForm();
  const location = useLocation();
  const currentPath = location.pathname;

  const myClass = currentPath.endsWith("/ar");
  const rtl = myClass ? Style.rtl : "";

  return (
    <div className={`${Style.register_form} ${rtl}`}>
      <form onSubmit={formik.handleSubmit}>
        <div className={Style.register_form_wrap}>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Name_First")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="First name"
              {...formik.getFieldProps("first_name")}
            />
            {formik.errors.first_name && formik.touched.first_name ? (
              <div className="error">{formik.errors.first_name}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Name_Last")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last name"
              {...formik.getFieldProps("last_name")}
            />
            {formik.errors.last_name && formik.touched.last_name ? (
              <div className="error">{formik.errors.last_name}</div>
            ) : null}
          </Form.Group>
          <div className={Style.radio_wrap}>
            <div className={Style.radio_btn}>
              <Form.Check
                type="radio"
                label={t("Male")}
                name="gender"
                onClick={() => {
                  formik.setFieldValue("gender", "male");
                }}
                onBlur={formik.handleBlur}
                id="Male"
              />
              <Form.Check
                type="radio"
                label={t("Femail")}
                name="gender"
                onClick={() => {
                  formik.setFieldValue("gender", "female");
                }}
                onBlur={formik.handleBlur}
                id="Female"
              />
              {formik.errors.gender && formik.touched.gender ? (
                <div className="error">{formik.errors.gender}</div>
              ) : null}
            </div>
          </div>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Email_Address")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              {...formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Phone_Number")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone Number"
              {...formik.getFieldProps("phone")}
            />
            {formik.errors.phone && formik.touched.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Date_of_Birth")}</Form.Label>
            <DatePicker
              onChange={(e) => formik.setFieldValue("dob", e)}
              value={formik.values.dob}
              name="dob"
              format="dd/MM/yyyy"
              clearIcon={null}
              calendarIcon={
                <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clipRule="evenodd"
                    d="M4 6V4H2v3h12V4h-2v2h-2V4H6v2H4Zm10 3H2v5h12V9ZM2 2h2V0h2v2h4V0h2v2h4v14H0V2h2Z"
                  />
                </svg>
              }
            />
            {formik.errors.dob && formik.touched.dob ? (
              <div className="error">{formik.errors.dob}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Nationality_Field")}</Form.Label>
            <Select
              id="nationality"
              name="nationality"
              options={[
                { label: "India", value: "India" },
                { label: "UAE", value: "India" },
              ]}
              onChange={(e) => formik.setFieldValue("nationality", e.value)}
              classNamePrefix="custom-select"
              placeholder={t("Nationality_Field_Select")}
            />

            {formik.errors.nationality && formik.touched.nationality ? (
              <div className="error">{formik.errors.nationality}</div>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group text-start">
            <Form.Label>{t("Living_Field")}</Form.Label>
            <Select
              id="living_situation"
              name="living_situation"
              options={[
                { label: "Single", value: "Single" },
                { label: "Married", value: "Married" },
              ]}
              onChange={(e) => formik.setFieldValue("living_situation", e.value)}
              classNamePrefix="custom-select"
              placeholder={t("Living_Field_Select")}
            />
            {formik.errors.living_situation && formik.touched.living_situation ? (
              <div className="error">{formik.errors.living_situation}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="form-group text-start">
            <Form.Label>{t("Address_Field")}</Form.Label>
            <Form.Control type="text" placeholder="Address" {...formik.getFieldProps("address")} />
            {formik.errors.address && formik.touched.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </Form.Group>
          <div className={Style.store_location}>
            <Form.Label>{t("Preferred_Field")}</Form.Label>
            <div className="d-flex">
              <Form.Check
                type="checkbox"
                name="sms_preferred"
                id="SMS"
                label={t("SMS_Field")}
                onChange={(event) => {
                  formik.setFieldValue("sms_preferred", event.target.checked);
                }}
                checked={formik.values.sms_preferred}
              />
              <Form.Check
                type="checkbox"
                name="email_preferred"
                id="email_preferred"
                label={t("Email_Field")}
                onChange={(event) => {
                  formik.setFieldValue("email_preferred", event.target.checked);
                }}
                checked={formik.values.email_preferred}
              />
            </div>
          </div>
          <div className={Style.radio_wrap}>
            <div className={`${Style.radio_btn} ${Style.store_location}`}>
              <Form.Check
                type="checkbox"
                name="toggle"
                id="toggle"
                label={t("Terms_Field")}
                onChange={(event) => {
                  formik.setFieldValue("toggle", event.target.checked);
                }}
                checked={formik.values.toggle}
              />
            </div>
            {formik.errors.toggle && formik.touched.toggle ? (
              <div className="error">{formik.errors.toggle}</div>
            ) : null}
          </div>
          <div className={Style.btn_wrap}>
            <button className="custom_verify_btn" onClick={handleShow} type="submit">
              {t("Next")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
