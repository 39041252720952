import Style from "./Home.module.scss";
import LoginForm from "../LoginForm";
import SubscriptionText from "../SubscriptionText";
import TermsandConditions from "../TermsandConditions";
import useHome from "./useHome";
import { useLocation } from 'react-router-dom';

const Home = () => {
  const { t } = useHome();
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith('/ar')
  const rtl = myClass ? Style.rtl : '';

  return (
    <>
      <section className={`${Style.delivery_subscription} ${rtl}`}>
       <div className="section_wrap">
          <div className="inner">
          <div className="row">
            <div className={`col-lg-6`}>
              <SubscriptionText />
              <div className={`${Style.desktop}`}>
                <LoginForm />
              </div>
            </div>
            <div className="col-lg-6 ">
              <figure>
                <img src="/assets/images/subscription-poster.png" alt="poster" />
              </figure>
              <div className={Style.mobile}>
              <LoginForm />
              </div>
            </div>
          </div>
          <TermsandConditions t={t} />
        </div>
        </div>
      </section>
    </>
  );
};

export default Home;
