import React from "react";
import { useParams } from "react-router-dom";
import Style from "../Home/Home.module.scss";
import DATA from "./term.json";

const TermsandConditions = ({ t }) => {
  const { lang: langSlug } = useParams();

  return (
    <div className={Style.termsandcondition}>
      <h2 className="h2">{t("TERMS_CONDITIONS")}</h2>
      <ol>
        {langSlug == "en"
          ? DATA?.TERM_DATA_EN?.map((item, i) => (
              <li key={i}>
                <p>{t(item?.description)}</p>
              </li>
            ))
          : DATA?.TERM_DATA_AR?.map((item, i) => (
              <li key={i}>
                <p>{t(item?.description)}</p>
              </li>
            ))}
      </ol>
    </div>
  );
};

export default TermsandConditions;
