import Footer from "../Footer";
import Menu from "../Menu";

const CommonLayout = ({ children }) => {
  return (
    <>
      <Menu />
      <div className="switcher">{children}</div>
      <Footer />
    </>
  );
};

export default CommonLayout;
