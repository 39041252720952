import { useTranslation } from "react-i18next";
import { useSwrStatic } from "../../helpers/swr";
import { setCookie } from "nookies";

const useSubscriptionText = () => {
  const { t } = useTranslation(["common"]);

  const { data: basicData } = useSwrStatic(`/basic-details`);

  if (basicData) {
    setCookie(null, "AMOUNT", basicData?.data?.amount);
  }

  return { t, basicData };
};

export default useSubscriptionText;
