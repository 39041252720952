import { parseCookies } from "nookies";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Summary from "../../components/Summary";

const SummaryPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const cookies = parseCookies();
      if (!cookies.USER_ACCESS_TOKEN) {
        navigate("/");
      }
    };

    checkToken();
  }, [navigate]);

  return <Summary />;
};

export default SummaryPage;
