import axios from "axios";
import { parseCookies } from "nookies";

const PRODUCTION = process.env.REACT_APP_PRODUCTION;

const BASE_URL =
  PRODUCTION === true ? process.env.REACT_APP_APIURL_LIVE : process.env.REACT_APP_STAGING_APIURL;

export const getAxiosInstance = async () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${
        parseCookies()?.USER_ACCESS_TOKEN && parseCookies()?.USER_ACCESS_TOKEN
      }`,
    },
  });
  instance.interceptors.request.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance;
};
