import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Style from "./VerifyOTP.module.scss";
import useVerifyOTP from "./useVerifyOTP";
import { useLocation } from "react-router-dom";
import OTPInput from "react-otp-input";

const VerifyOTP = ({ shows, isTimerRunning, setIsTimerRunning, onHides }) => {
  const { t, setTimer, timer, formik, isSubmitLoading, handleReset, handleResend } = useVerifyOTP({
    onHides,
    isTimerRunning,
    setIsTimerRunning,
  });
  const location = useLocation();
  const currentPath = location.pathname;

  const rtl = currentPath === "/ar" ? Style.rtl : "";
  return (
    <Modal
      show={shows}
      onHide={() => {
        formik.resetForm();
        setIsTimerRunning(false);
        setTimer(59);
        onHides();
      }}
      centered
      className={`${Style.modal} ${rtl}`}
    >
      <button
        variant="secondary"
        onClick={() => {
          formik.resetForm();
          onHides();
          setIsTimerRunning(false);
          setTimer(59);
        }}
        className={Style.close}
      ></button>

      <Modal.Body>
        <h2 className={Style.title}>{t("ENTER_OTP")}</h2>
        <p className={Style.head_text}>
          {t("OTP_SEND")} <span>{/* {mobileRef?.current.substring(0, 3)}  */}971XXXXXXXX</span>
        </p>
        <form onSubmit={formik.handleSubmit}>
          <Form.Group className={`form-group text-start ${Style.otpform}`}>
            <Form.Label></Form.Label>

            <OTPInput
              value={formik.values.otp}
              onChange={(value) => {
                formik.setFieldValue("otp", parseInt(value));
                if (value?.length === 6) {
                  formik.handleSubmit();
                }
              }}
              numInputs={6}
              isInputNum={true}
              shouldAutoFocus={true}
              name="otp"
              inputType={"number"}
              containerStyle={Style.otpInputWrp}
              inputStyle={`flex-fill ${Style.otpInput}`}
              renderInput={(props) => <Form.Control {...props} />}
            />
          </Form.Group>

          {formik.touched.otp && formik.errors.otp ? (
            <div className="formikError">{formik.errors.otp}</div>
          ) : null}
          <button
            className={`${isSubmitLoading ? "loading custom_verify_btn" : " custom_verify_btn"}`}
            type="submit"
          >
            {t("SUBMIT")}
          </button>
        </form>

        <button className={Style.resend_btn} onClick={handleReset}>
          {t("RESET_OTP")}
        </button>

        {!isTimerRunning && (
          <button className={Style.resend_btn} onClick={handleResend}>
            {t("RESEND_OTP")}
          </button>
        )}

        {isTimerRunning && timer > 0 && (
          <p className="resend">
            {t("RESEND_OTP")} ({t("IN")}&nbsp;
            <span>{timer}</span> {t("SECONDS")})
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VerifyOTP;
