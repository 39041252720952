import { useEffect, useState } from "react";
import platform from "platform";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../logic/useScreen";
import { destroyCookie, parseCookies } from "nookies";
import { PaymentInitiate } from "./api";
import { getMethodInstance } from "../Subscription/api";
import { useNavigate } from "react-router-dom";

let paymentApi;
let count = 0;

const useFormPayment = () => {
  const [show, setShow] = useState(false);
  const [iFrame, setIFrame] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [loadingShimmer, setLoadingShimmer] = useState(false);
  const [iFrameNull, setIFrameNull] = useState(false);
  const [cardClick, setCardClick] = useState(true);
  const [applePayClick, setApplePayClick] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [pendingModal, setPendingModal] = useState(false);
  const navigate = useNavigate();

  const cardSelect = () => {
    setCardClick(true);
    setApplePayClick(false);
  };

  const applePaySelect = () => {
    setApplePayClick(true);
    setCardClick(false);
  };

  const { t } = useTranslation(["common"]);

  const { height, width } = useWindowDimensions();

  const timezoneOffset = new Date().getTimezoneOffset();

  const lang = localStorage.getItem("language_type");

  var userLanguage =
    lang === "ar" ? "ar-ae" : window.navigator.userLanguage || window.navigator.language;

  const initiatePurchase = async (params) => {
    PaymentInitiate(params)
      .then((response) => {
        if (response?.data?.code == "200") {
          setShow(true);
          setLoadingShimmer(false);
          setIFrame(response?.data?.data?.iframe);
          setIFrameNull(response?.data?.data?.payment_status == "FAILURE");
          if (response?.data?.data?.payment_status == "FAILURE") {
            setShow(false);
          }
        } else {
          initiatePurchase(params);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (iFrameNull == true) {
      setLoadingShimmer(false);
    }
    setTimeout(() => {
      setIFrameNull(false);
    }, 2500);
  }, [iFrameNull]);

  useEffect(() => {
    if (paymentStatus == 1) {
      setPendingModal(false);
      setShow(false);
      navigate(`/summary/${parseCookies()?.ORDER_ID}/${lang}`);
    } else if (paymentStatus == 2) {
      setPendingModal(false);
      setShow(false);
      navigate(`/summary/${parseCookies()?.ORDER_ID}/${lang}`);
    }
  }, [paymentStatus]);

  const PaymentCall = async (id) => {
    setPendingModal(true);
    if (id) {
      await getMethodInstance(`payment-status/${id}`)
        .then((res) => {
          if (res?.data?.payment_status == 0) {
            setPendingModal(true);
            PaymentCall(id);
          } else {
            setPaymentStatus(res?.data?.payment_status);
          }
        })
        .catch(() => {
          setTimeout(() => {
            alert(t("Something_wrong"));
            setPendingModal(false);
            setShow(false);
            if (lang === "ar") {
              navigate("/ar");
              window.location.reload();
            } else if (lang === "en") {
              navigate("/en");
              window.location.reload();
            } else {
              navigate("/en");
            }
          }, 6000);
        });
    }
  };

  useEffect(() => {
    if (iFrame) {
      const formId = document.getElementById("threedsChallengeRedirectForm");
      const formId2 = document.getElementById("redirectTo3ds1Form");
      const formCancel = document.getElementById("threedsFrictionLessRedirectForm");
      if (formId) {
        formId.submit();
      } else if (formId2) {
        formId2.submit();
      } else if (formCancel) {
        formCancel.submit();
      }
      const iframeDiv = document.getElementById("challengeFrame");
      const iframeDiv2 = document.getElementById("redirectTo3ds1Frame");
      const formCancelDiv = document.getElementById("challengeFrame");
      if (iframeDiv && !formCancel) {
        iframeDiv.addEventListener("load", (e) => {
          if (count > 1) {
            document.getElementById("threedsChallengeRedirect").style.height = "100vh";

            PaymentCall(parseCookies()?.ORDER_ID);
          } else count++;
        });
      } else if (iframeDiv2) {
        iframeDiv2.addEventListener("load", (e) => {
          if (count <= 1) {
            document.getElementById("redirectTo3ds1Frame").style.height = "100vh";
          }
          if (count > 1) {
            document.getElementById("redirectTo3ds1Frame").style.height = "100vh";

            PaymentCall(parseCookies()?.ORDER_ID);
          } else count++;
        });
      } else if (formCancelDiv && formCancel) {
        formCancelDiv.addEventListener("load", (e) => {
          if (count == 0) {
            document.getElementById("threedsFrictionLessRedirectForm").style.height = "100vh";
            PaymentCall(parseCookies()?.ORDER_ID);
          } else count++;
        });
      }
    }
  }, [iFrame]);

  useEffect(() => {
    if (iFrameNull == true) {
      setLoadingShimmer(false);
    }
    setTimeout(() => {
      setIFrameNull(false);
    }, 2500);
  }, [iFrameNull]);

  const paymentConfigure = async (session_id) => {
    if (window.PaymentSession !== undefined) {
      paymentApi = window.PaymentSession;
      paymentApi.configure({
        session: session_id,
        fields: {
          card: {
            number: "#card-number",
            securityCode: "#security-code",
            expiryMonth: "#expiry-month",
            expiryYear: "#expiry-year",
            nameOnCard: "#cardholder-name",
          },
        },

        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: (response) => {},
          formSessionUpdate: async (response) => {
            if (response.status) {
              if ("ok" === response.status) {
                const payload = {
                  order_id: parseCookies()?.ORDER_ID,
                  session_id: parseCookies()?.SESSION_ID,
                  browser: platform.name,
                  colorDepth: window.screen.colorDepth,
                  javaEnabled: false,
                  language: userLanguage,
                  screenHeight: height,
                  screenWidth: width,
                  timeZone: timezoneOffset,
                };

                initiatePurchase(payload);

                //check if the security code was provided by the user
                if (response.sourceOfFunds.provided.card.securityCode) {
                  setPaymentError("");
                }

                //check if the user entered a Mastercard credit card
                if (response.sourceOfFunds.provided.card.scheme === "MASTERCARD") {
                  setPaymentError("");
                }
              } else if ("fields_in_error" === response.status) {
                if (response.errors.cardNumber) {
                  injectErrorToForm(t("card_num_missing"));
                  document.getElementById("payButton").classList.remove("loading");
                }
                if (response.errors.expiryYear) {
                  injectErrorToForm(t("Expiry_year"));
                  document.getElementById("payButton").classList.remove("loading");
                }
                if (response.errors.expiryMonth) {
                  injectErrorToForm(t("Expiry_month"));
                  document.getElementById("payButton").classList.remove("loading");
                }
                if (response.errors.securityCode) {
                  injectErrorToForm(t("Security_code"));
                  document.getElementById("payButton").classList.remove("loading");
                }
              } else if ("request_timeout" === response.status) {
                injectErrorToForm(
                  "Session update failed with request timeout: " + response.errors.message
                );
              } else if ("system_error" === response.status) {
                injectErrorToForm(
                  "Session update failed with system error: " + response.errors.message
                );
              }
            } else {
              injectErrorToForm("Session update failed: " + response);
            }
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT",
          },
        },
      });
    }
  };

  const pay = () => {
    paymentApi.updateSessionFromForm("card");
    setPaymentError("");
  };

  const injectErrorToForm = (error) => {
    setPaymentError(error);
    setLoadingShimmer(false);
  };

  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(9), (v, i) => (
      <option key={i} value={year + i}>
        {year + i}
      </option>
    ));
  };

  const handleHide = () => {
    setShow(false);
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      navigate("/ar");
      window.location.reload();
    } else {
      navigate("/en");
      window.location.reload();
    }
  };
  return {
    show,
    iFrame,
    cardClick,
    paymentError,
    pendingModal,
    applePayClick,
    paymentStatus,
    loadingShimmer,
    t,
    pay,
    cardSelect,
    handleHide,
    getDropList,
    applePaySelect,
    paymentConfigure,
    setLoadingShimmer,
  };
};

export default useFormPayment;
