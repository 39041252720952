import React from "react";
import Style from "./SubscriptionDate.module.scss";
import { useLocation } from 'react-router-dom';

const SubscriptionDate = ({ t, props }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const myClass = currentPath.endsWith('/ar')
  const rtl = myClass ? Style.rtl : '';

  return (
    <div className={`${Style.subdate} ${rtl}`}>
      <div className={Style.box}>
        <h2 className={Style.title}>{t("START_DATE")}</h2>
        <p>{props?.data?.start_date}</p>
      </div>
      <div className={Style.box}>
        <h2 className={Style.title}>{t("END_DATE")}</h2>
        <p>{props?.data?.end_date}</p>
      </div>
    </div>
  );
};

export default SubscriptionDate;
